import { ImageUser } from "./imageUser/index.js";
import { Container } from "./styled.js";
import storage from 'local-storage'

export function Cabecalho(props) {

  function lerNome() {
    let nome = storage('dmk-user')?.user?.info?.nome;

    if (!nome) return '';
    const esp = nome.indexOf(' ')
    if (esp >= 0)
      return nome.substr(0, esp);
    return nome;
  }

  return (
    <Container tema={props.tema}>
      <div className="group-items">
        <div className="mensagem"> Olá {lerNome()}, bom trabalho! </div>
        <ImageUser mediaMaxWidth={props.mediaMaxWidth} />
      </div>
    </Container>
  )
}

